
import { Component, Prop, Vue } from 'vue-property-decorator'
import { apiGetList, apiPostCreate, apiPostEdit, apiPostDelete } from '@/api/meeting_exhibitor'
import { apiGetList as apiGetOrgList } from '@/api/organization'
import { ElForm } from 'element-ui/types/form'

@Component(
  {
  name: 'MeetingExhibitor',
  filters: {
  statusFilter: (status: string) => {
  const statusMap: { [key: string]: string } = {
  0: 'success',
  1: 'success',
  2: 'danger'
  }
  return statusMap[status]
  },
  parseDate: (timestamp: string) => {
  return new Date(timestamp).toISOString()
  }
  }
  }
)

export default class extends Vue {
    @Prop(Number) readonly meetingId!: number

    private list: any[] = []
    private list_teacher:any[] = []
    private listLoading = true
    private listQuery = {
      page: 1,
      pageSize: 20
    }

    private moduleCNName='参展企业'
    private isShowDialog = false
    private dialogText:object={
      update: '编辑',
      create: '添加'
    }

    public guestTypeList = [
      { value: 1, label: '钻石展位' },
      { value: 2, label: '金牌展位' },
      { value: 3, label: '标准展位' }
    ]

    private orgList: any[] = []

    private rules = {}

    private dialogStatus = 'create'

    private tempdata:any = {

    }

    created() {
      this.getOrgList()
      this.getList()
    }

    private async getList() {
      this.listLoading = true
      const { data } = await apiGetList({ meeting_id: this.meetingId })
      this.list = data.map((v:any) => { v.action = ''; return v })
      // Just to simulate the time of the request
      setTimeout(() => {
        this.listLoading = false
      }, 0.5 * 1000)
    }

    private async getOrgList() {
      const { data } = await apiGetOrgList({ page: 1, pageSize: 999 })
      this.orgList = this.orgList.concat(data)
    }

    private createData() {
      (this.$refs.dataForm as ElForm).validate((valid) => {
        if (valid) {
          apiPostCreate(this.tempdata).then(res => {
            if (res.status === 1) {
              this.tempdata.id = res.data
              this.$notify({
                title: '成功',
                message: '创建成功',
                type: 'success',
                duration: 2000
              })
              this.isShowDialog = false
              this.getList()
            }
          })
        }
      })
    }

    private handleEdit(row:any) {
      this.tempdata = { ...row }
      row.action = 'update'
    }

    private async handleSave(row:any, i:number) {
      if (row.action === 'update') {
        const { data } = await apiPostEdit(row)
        this.getList()
        console.log('update res', data)
      }
      if (row.action === 'create') {
        const { data } = await apiPostCreate(row)
        this.getList()
        console.log('create res', data)
      }
    }

    private handleCreate() {
      this.resetTemp()
      this.list.push(this.tempdata)
    }

    private handleCancel(row:any, i:number) {
      console.log(121212)
      if (row.action === 'create') {
        this.list.splice(i, 1)
      }
      if (row.action === 'update') {
        this.list.splice(i, 1, { ...this.tempdata })
      }
    }

    private handleDelete(row:any) {
      this.$confirm('确定要删除吗？', '提示', { type: 'warning', confirmButtonText: '确定', cancelButtonText: '取消' }).then(
        () => {
          apiPostDelete(row).then((res) => {
            if (res.status === 1) {
              this.$notify({
                title: '成功',
                message: '操作成功',
                type: 'success',
                duration: 2000
              })
              this.list = this.list.filter((v, i) => {
                return v.id !== row.id
              })
            } else {
              this.$notify({
                title: '提示',
                message: '操作失败',
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      )
    }

    public handleExpand(row:any) {
      this.expand_id = row.id
      this.loadDetail()
    }

    private loadDetail() {
      console.log(this.expand_id)
    }

    private resetTemp() {
      this.tempdata = {
        id: '',
        meeting_id: this.meetingId,
        org_id: 1,
        type: 1,
        position: '',
        listorder: '',
        action: 'create'
      }
    }
}
