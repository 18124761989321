
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component(
  {
  name: 'Upbox',
  components:{

  }
  }
)
export default class extends Vue {
    @Prop(String) readonly picUrl!: string
    @Prop(String) readonly uptype!: string

    private upUrl= process.env.VUE_APP_BASE_API + 'upload/attachment'
    private upData = {}

    get PicShowUrl() {
      return ((this.picUrl || '').indexOf('http') > -1 ? '' : 'https://api.yanlumed.com/uploads/') + this.picUrl
    }

    created() {
      this.upData = {
        userid: this.$store.state.user.userid,
        type: this.uptype
      }
    }

    private generateHeaders() {
      return { 'Authori-zation': this.$store.state.user.token }
    }

    private previewPic(url) {
      window.open(url)
    }

    private beforeAvatarUpload(file) {
      this.upData.uploadfile = file
    }

    private handleAvatarSuccess(res, file) {
      console.log('upres', res)
      if (res.status === 1) {
        this.$emit('upres', res.data)
      }
    }

    private confirmDel() {
      this.$confirm('确定要删除吗？', '提示', { type: 'warning', confirmButtonText: '确定', cancelButtonText: '取消' }).then(
        () => {
          this.$emit('del')
        }
      )
    }
}
