
import { Component, Vue, Prop } from 'vue-property-decorator'
import { apiGetList, apiPostCreate, apiPostDelete, apiPostEdit } from '@/api/meeting_ticket'
import { ElForm } from 'element-ui/types/form'

@Component({
  name: 'MettingTicket',
  filters: {
  statusFilter: (status: string) => {
  const statusMap: { [key: string]: string } = {
  1: 'success',
  0: 'danger'
  }
  return statusMap[status]
  },
  parseDate: (timestamp: string) => {
  return new Date(timestamp).toISOString()
  }
  }
  })
export default class extends Vue {
  @Prop(Number) readonly meetingId!: number

    private list: any[] = []
    private is_verify = '1'
    private type = '1'
    private set_type = '1'
    private is_voucher = '1'
    private is_signup_expire = '1'
    private is_attendee = '1'
    private signup_expire_time = ''
    private is_expire = 1
    private listLoading = true
    private listQuery = {
      page: 1,
      pageSize: 20
    }

    private moduleCNName='票种'
    private isShowDialog = false
    private dialogText:object={
      update: '编辑',
      create: '添加'
    }

    // private meetingId = 10

    private rules = {}

    private dialogStatus = 'create'

    private tempdata:any = {

    }

    created() {
      this.getList()
    }

    private async getList() {
      this.listLoading = true
      const { data } = await apiGetList({ meeting_id: this.meetingId })
      this.list = data
      // Just to simulate the time of the request
      setTimeout(() => {
        this.listLoading = false
      }, 0.5 * 1000)
    }

    private createData() {
      (this.$refs.dataForm as ElForm).validate((valid:boolean) => {
        if (valid) {
          apiPostCreate(this.tempdata).then(res => {
            if (res.status === 1) {
              this.tempdata.id = res.data
              this.$notify({
                title: '成功',
                message: '创建成功',
                type: 'success',
                duration: 2000
              })
              this.isShowDialog = false
              this.getList()
            }
          })
        }
      })
    }

    private updateData() {
      (this.$refs.dataForm as ElForm).validate((valid:boolean) => {
        if (valid) {
          apiPostEdit(this.tempdata).then(res => {
            console.log(res)
            this.isShowDialog = false
            this.getList()
          })
        }
      })
    }

    private handleEdit(row:any) {
      this.dialogStatus = 'update'
      this.tempdata = Object.assign({}, row)
      this.isShowDialog = true
      this.$nextTick(() => {
        (this.$refs.dataForm as ElForm).clearValidate()
      })
    }

    private handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.isShowDialog = true
    }

    private handleDelete(row:any) {
      this.$confirm('确定要删除吗？', '提示', { type: 'warning', confirmButtonText: '确定', cancelButtonText: '取消' }).then(
        () => {
          apiPostDelete(row).then((res) => {
            if (res.status === 1) {
              this.$notify({
                title: '成功',
                message: '操作成功',
                type: 'success',
                duration: 2000
              })
              this.list = this.list.filter((v, i) => {
                return v.id !== row.id
              })
            } else {
              this.$notify({
                title: '提示',
                message: '操作失败',
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      )
    }

    private resetTemp() {
      this.tempdata = {
        id: '',
        name: '',
        meeting_id: this.meetingId,
        type: '',
        set_type: '',
        price: '',
        price_discount: '',
        price_avg: '',
        ticket_nums: '',
        ticket_user_nums: '',
        is_attendee: '',
        is_verify: '',
        is_voucher: '',
        is_signup_expire: '',
        signup_expire_time: '',
        is_expire: '',
        listorder: 1,
        status: ''
      }
    }
}
