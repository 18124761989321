
import { Component, Vue } from 'vue-property-decorator'
import { apiGetList, apiPostCreate, apiPostDelete, apiPostEdit } from '@/api/category'
import { arToTree } from '@/utils/arandtree'
import { ElForm } from 'element-ui/types/form'

@Component({
  name: 'CategoryIndex',
  filters: {
  statusFilter: (status: string) => {
  const statusMap: { [key: string]: string } = {
  published: 'success',
  draft: 'gray',
  deleted: 'danger'
  }
  return statusMap[status]
  },
  parseDate: (timestamp: string) => {
  return new Date(timestamp).toISOString()
  }
  }
  })
export default class extends Vue {
    private list: any[] = []
    private listLoading = true
    private listQuery = {
      page: 1,
      pageSize: 20
    }

    private moduleCNName='分类'

    private isShowDialog = false
    private dialogText:object={
      update: '编辑',
      create: '添加'
    }

    private dialogStatus = 'create'

    private rules = {}
    private tempdata:any = {

    }

    private defaultProps= {
      label: 'name'
    }

    created() {
      this.getList()
    }

    private async getList() {
      this.listLoading = true
      const { data } = await apiGetList(this.listQuery)
      this.list = arToTree(data, 'id', 'parentid')
      // Just to simulate the time of the request
      setTimeout(() => {
        this.listLoading = false
      }, 0.5 * 1000)
    }

    private createData() {
      (this.$refs.dataForm as ElForm).validate((valid:boolean) => {
        if (valid) {
          apiPostCreate(this.tempdata).then(res => {
            if (res.status === 1) {
              this.tempdata.id = res.data
              this.$notify({
                title: '成功',
                message: '创建成功',
                type: 'success',
                duration: 2000
              })
              this.isShowDialog = false
              this.getList()
            }
          })
        }
      })
    }

    private updateData() {
      (this.$refs.dataForm as ElForm).validate((valid) => {
        if (valid) {
          apiPostEdit(this.tempdata).then(res => {
            console.log(res)
          })
        }
      })
    }

    private handleEdit(row:any) {
      this.dialogStatus = 'update'
      this.tempdata = Object.assign({}, row)
      this.isShowDialog = true
      this.$nextTick(() => {
        (this.$refs.dataForm as ElForm).clearValidate()
      })
    }

    private handleCreate(row:any, parenttype:string) {
      this.resetTemp()
      if (parenttype === 'parent') {
        this.tempdata.parentid = row.id
        this.tempdata.parentname = row.name
      } else {
        this.tempdata.type = row.type
      }
      this.dialogStatus = 'create'
      this.isShowDialog = true
    }

    private handleDelete(row:any) {
      this.$confirm('确定要删除吗？', '提示', { type: 'warning', confirmButtonText: '确定', cancelButtonText: '取消' }).then(
        () => {
          apiPostDelete(row).then((res) => {
            if (res.status === 1) {
              this.$notify({
                title: '成功',
                message: '操作成功',
                type: 'success',
                duration: 2000
              })
              this.list = this.list.filter((v, i) => {
                return v.id !== row.id
              })
            } else {
              this.$notify({
                title: '提示',
                message: '操作失败',
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      )
    }

    private resetTemp() {
      this.tempdata = {
        id: '',
        name: '',
        type: 1,
        parentid: 0,
        parentname: '',
        status: 1
      }
    }

    private async loadNode(node, resolve) {
      const res = await apiGetList({ parentid: !node.level ? 0 : node.data.id })
      resolve(res.data)
    }
}
