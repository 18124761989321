
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { apiGetList, apiPostCreate, apiPostDelete, apiPostEdit } from '@/api/meeting_subforum'
import {
  apiPostCreate as apiPostAgendaCreate,
  apiPostDelete as apiPostAgendaDelete,
  apiPostEdit as apiPostAgendaEdit
} from '@/api/meeting_agenda'
import { apiGetList as apiGetGuestList } from '@/api/meeting_guest'
import { arToTree } from '@/utils/arandtree'
import { ElForm } from 'element-ui/types/form'
import { parseDate } from '@/utils/datetime'

@Component({
  name: 'MettingSubforum',
  filters: {
  statusFilter: (status: string) => {
  const statusMap: { [key: string]: string } = {
  published: 'success',
  draft: 'gray',
  deleted: 'danger'
  }
  return statusMap[status]
  },
  parseDate: (timestamp: string) => {
  return new Date(timestamp).toISOString()
  }
  }
  })
export default class extends Vue {
    @Prop(Number) readonly meetingId!: number

    private list: any[] = []
    private listLoading = true
    private listQuery = {
      page: 1,
      pageSize: 20
    }

    private guestList:any[] = []

    private isShowDialog = false
    private dialogText:object={
      update: '编辑',
      create: '添加'
    }

    private dialogStatus = 'create'

    private rules = {}
    private tempdata:any = {

    }

    private moduleCNName= '日程'

    private defaultProps= {
      label: 'name',
      children: 'agenda'
    }

    private rules1:any = {
      name: [{ required: true, message: '请输入名称', trigger: 'change' }],
      forum_time: [{ required: true, message: '请输入时间', trigger: 'change' }],
      address: [{ required: true, message: '请输入地址', trigger: 'change' }]
    }

    private rules2:any = {
      name: [{ required: true, message: '请输入名称', trigger: 'change' }],
      begin_time: [{ required: true, message: '请输入开始时间', trigger: 'change' }],
      end_time: [{ required: true, message: '请输入结束', trigger: 'change' }],
      guest_id: [{ required: true, message: '请选择嘉宾', trigger: 'change' }]
    }

    @Watch('isShowDialog')
    isShowDialogChanged(newVal:boolean) {
      this.moduleCNName = this.tempdata.subforum_id ? '议程' : '日程'
      if (newVal) {
        this.$nextTick(() => {
          (this.$refs['dataForm' + (this.tempdata.subforum_id ? '2' : '1')] as ElForm).clearValidate()
        })
      }
    }

    async created() {
      await this.getGuestList()
      this.getList()
    }

    private async getGuestList() {
      const { data } = await apiGetGuestList({ meeting_id: this.meetingId })
      this.guestList = data
    }

    private async getList() {
      this.listLoading = true
      const { data } = await apiGetList({ meeting_id: this.meetingId })
      this.list = data
      //   this.list = arToTree(data, 'id', 'parentid')
      // Just to simulate the time of the request
      setTimeout(() => {
        this.listLoading = false
      }, 0.5 * 1000)
    }

    private createData() {
      (this.$refs['dataForm' + (this.tempdata.subforum_id ? '2' : '1')] as ElForm).validate((valid:boolean) => {
        if (valid) {
          const tmpdata = { ...this.tempdata }
          if (!tmpdata.subforum_id) {
            tmpdata.forum_time = new Date(tmpdata.forum_time).valueOf() / 1000
          }
          const fn = !tmpdata.subforum_id ? apiPostCreate : apiPostAgendaCreate
          fn(tmpdata).then(res => {
            if (res.status === 1) {
              this.tempdata.id = res.data
              this.$notify({
                title: '成功',
                message: '创建成功',
                type: 'success',
                duration: 2000
              })
              this.isShowDialog = false
              this.getList()
            }
          })
        }
      })
    }

    private updateData() {
      (this.$refs['dataForm' + (this.tempdata.subforum_id ? '2' : '1')] as ElForm).validate((valid) => {
        if (valid) {
          const tmpdata = { ...this.tempdata }
          if (!tmpdata.subforum_id) {
            tmpdata.forum_time = new Date(tmpdata.forum_time).valueOf() / 1000
          }
          const fn = !tmpdata.subforum_id ? apiPostEdit : apiPostAgendaEdit
          fn(tmpdata).then(res => {
            console.log(res)
            this.isShowDialog = false
            this.$notify({
              title: '成功',
              message: '编辑成功',
              type: 'success',
              duration: 2000
            })
            this.getList()
          })
        }
      })
    }

    private handleEdit(row:any) {
      this.dialogStatus = 'update'
      this.resetTemp()
      const tmpdata = Object.assign({}, this.tempdata, row)
      if (!tmpdata.subforum_id) {
        tmpdata.forum_time = parseDate(tmpdata.forum_time, '{y}-{m}-{d}')
      }
      this.tempdata = { ...tmpdata }
      this.isShowDialog = true
      this.$nextTick(() => {
        (this.$refs['dataForm' + (this.tempdata.subforum_id ? '2' : '1')] as ElForm).clearValidate()
      })
    }

    private handleCreate(row:any, parenttype:string) {
      this.resetTemp()
      if (parenttype === 'parent') {
        this.tempdata.subforum_id = row.id
      }
      this.dialogStatus = 'create'
      this.isShowDialog = true
    }

    private handleDelete(row:any) {
      this.$confirm('确定要删除吗？', '提示', { type: 'warning', confirmButtonText: '确定', cancelButtonText: '取消' }).then(
        () => {
          const fn = !row.subforum_id ? apiPostDelete : apiPostAgendaDelete
          fn(row).then((res) => {
            if (res.status === 1) {
              this.$notify({
                title: '成功',
                message: '操作成功',
                type: 'success',
                duration: 2000
              })
              this.getList()
            } else {
              this.$notify({
                title: '提示',
                message: '操作失败',
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      )
    }

    private resetTemp() {
      this.tempdata = {
        id: '',
        subforum_id: '',
        name: '',
        forum_time: '',
        address: '',
        status: '',
        guest_id: '',
        begin_time: '',
        end_time: '',
        listorder: '',
        meeting_id: this.meetingId
      }
    }

    private parseDate(a:any) {
      return parseDate(a, '{y}-{m}-{d}')
    }
}
