import request from '@/utils/request'

export const apiGetList = (params: any) =>
  request({
    url: '/organization/lists',
    method: 'get',
    params
  })

export const apiPostCreate = (data: any) =>
  request({
    url: '/organization/add',
    method: 'post',
    data
  })

export const apiPostDelete = (data: any) =>
  request({
    url: '/organization/del',
    method: 'post',
    data
  })

export const apiPostEdit = (data: any) =>
  request({
    url: '/organization/edit',
    method: 'post',
    data
  })
