import request from '@/utils/request'

export const apiGetList = (params: any) =>
  request({
    url: '/meeting/agenda_lists',
    method: 'get',
    params
  })

export const apiPostCreate = (data: any) =>
  request({
    url: '/meeting/agenda_add',
    method: 'post',
    data
  })

export const apiPostDelete = (data: any) =>
  request({
    url: '/meeting/agenda_del',
    method: 'post',
    data
  })

export const apiPostEdit = (data: any) =>
  request({
    url: '/meeting/agenda_edit',
    method: 'post',
    data
  })
