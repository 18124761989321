
import { Component, Vue } from 'vue-property-decorator'
import { apiGetList, apiPostCreate, apiPostDelete, apiPostEdit } from '@/api/meeting'
import { apiGetZone } from '@/api/common'
import { parseDate } from '@/utils/datetime'
import { ElForm } from 'element-ui/types/form'
import Guest from './meeting_guest.vue'
import Org from './meeting_org.vue'
import Exhibitor from './meeting_exhibitor.vue'
import Subforum from './meeting_subforum.vue'
import Ticket from './meeting_ticket.vue'
import Upbox from '@/components/Upbox'

@Component(
  {
  name: 'MettingIndex',
  components:{
  Guest,
  Org,
  Exhibitor,
  Subforum,
  Ticket,
  Upbox
  },
  filters: {
  statusFilter: (status: string) => {
  const statusMap: { [key: string]: string } = {
  0: 'success',
  1: 'success',
  2: 'danger'
  }
  return statusMap[status]
  },

  }
  }
)
export default class extends Vue {
    private list: any[] = []
    private list_teacher:any[] = []
    private listLoading = true
    private listQuery = {
      page: 1,
      pageSize: 20
    }

    public expand_id = 0

    private moduleCNName='会议'
    private isShowDialog = false
    private dialogText:object={
      update: '编辑',
      create: '添加'
    }

    private rules = {}

    private dialogStatus = 'create'

    private tempdata:any = {

    }

    private areaFields =['provinceid', 'cityid', 'countyid']
    private areaSeleList = [[{ id: 1, name: '北京' }], [], []]

    private areas =[]
    private areaProp = {
      label: 'name',
      value: 'id',
      expandTrigger: 'click'
    }

    // private cascProp={
    //   lazy: true,
    //   lazyLoad(node, resolve) {
    //     console.log(111, node)
    //   }
    // }

    created() {
      this.getList()
      this.getAreaList()
    }

    private async getList() {
      this.listLoading = true
      const { data } = await apiGetList(this.listQuery)
      this.list = data
      // Just to simulate the time of the request
      setTimeout(() => {
        this.listLoading = false
      }, 0.5 * 1000)
    }

    private createData() {
      (this.$refs.dataForm as ElForm).validate((valid) => {
        if (valid) {
          const tmpdata = { ...this.tempdata }
          tmpdata.begin_time = new Date(tmpdata.begin_time).valueOf() / 1000
          tmpdata.end_time = new Date(tmpdata.end_time).valueOf() / 1000
          apiPostCreate(tmpdata).then(res => {
            if (res.status === 1) {
              this.tempdata.id = res.data
              this.$notify({
                title: '成功',
                message: '创建成功',
                type: 'success',
                duration: 2000
              })
              this.isShowDialog = false
              this.getList()
            }
          })
        }
      })
    }

    private updateData() {
      (this.$refs.dataForm as ElForm).validate((valid:any) => {
        if (valid) {
          const tmpdata = { ...this.tempdata }
          tmpdata.begin_time = new Date(tmpdata.begin_time).valueOf() / 1000
          tmpdata.end_time = new Date(tmpdata.end_time).valueOf() / 1000
          apiPostEdit(tmpdata).then(res => {
            console.log(res)
            this.$notify({ title: '成功', message: '编辑成功', type: 'success', duration: 2000 })
            this.isShowDialog = false
            this.getList()
          })
        }
      })
    }

    private handleEdit(row:any) {
      this.dialogStatus = 'update'
      const tmpdata = Object.assign({}, row)
      tmpdata.begin_time = parseDate(tmpdata.begin_time, '{y}-{m}-{d}')
      tmpdata.end_time = parseDate(tmpdata.end_time, '{y}-{m}-{d}')
      if (tmpdata.cityid) this.getAreaList(tmpdata.provinceid, 1)
      if (tmpdata.countyid) this.getAreaList(tmpdata.cityid, 2)
      this.tempdata = tmpdata
      this.isShowDialog = true
      this.$nextTick(() => {
        (this.$refs.dataForm as ElForm).clearValidate()
      })
    }

    private handleCreate() {
      this.resetTemp()
      this.$set(this.areaSeleList, 1, [])
      this.$set(this.areaSeleList, 2, [])
      this.dialogStatus = 'create'
      this.isShowDialog = true
    }

    private handleDelete(row:any) {
      this.$confirm('确定要删除吗？', '提示', { type: 'warning', confirmButtonText: '确定', cancelButtonText: '取消' }).then(
        () => {
          apiPostDelete(row).then((res) => {
            if (res.status === 1) {
              this.$notify({
                title: '成功',
                message: '操作成功',
                type: 'success',
                duration: 2000
              })
              this.list = this.list.filter((v, i) => {
                return v.id !== row.id
              })
            } else {
              this.$notify({
                title: '提示',
                message: '操作失败',
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      )
    }

    public handleExpand(row:any) {
      if (this.expand_id === row.id) {
        this.expand_id = 0
      } else {
        this.expand_id = row.id
        this.loadDetail()
      }
    }

    private loadDetail() {
      console.log(this.expand_id)
    }

    private resetTemp() {
      this.tempdata = {
        id: '',
        catid: '',
        title: '',
        numbers: '',
        aid: '',
        poster_path: '',
        content: '',
        guide: '',
        begin_time: '',
        end_time: '',
        provinceid: '',
        cityid: '',
        countyid: '',
        address: '',
        status: 1
      }
    }

    private getAreaList(zid = 0, deep = 0) {
      apiGetZone({ parentid: zid }).then(res => {
        this.$set(this.areaSeleList, deep, res.data)
      })
    }

    private areaChange(e, deep) {
      for (let i = deep + 1; i < this.areaFields.length; i++) {
        this.$set(this.areaSeleList, i, [])
        this.tempdata[this.areaFields[i]] = ''
      }
      this.getAreaList(e, deep + 1)
    }

    private upResHandler(res) {
      this.tempdata.aid = res.aid
      this.tempdata.poster_path = res.filename
    }

    private upDelHandler() {
      this.tempdata.poster_path = ''
    }
}
